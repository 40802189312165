import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    breadcrumb: []
  },
  mutations: {
    addBreadcrumb(state, val) {
      state.breadcrumb = val
      sessionStorage.setItem('tag',JSON.stringify(val))
    }
  },
  actions: {
  },
  modules: {
  }
})
