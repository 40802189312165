<template>
  <el-menu
    :default-active="active"
    @select="handleClick"
    router
    background-color="#304156"
    text-color="#fff"
  >
    <my-menu v-for="item in menu" :key="item.path" :route="item"></my-menu>
  </el-menu>
</template>
<script>
import MyMenu from "./mymenu";
import { mapMutations } from "vuex";
import menu from "../../utils/menu.js";
export default {
  name: "myAside",
  components: {
    MyMenu,
  },
  data() {
    return {
      menu: [],
      list: [],
    };
  },

  computed: {
    active: {
      get(val) {
        return this.$route.path;
      },
      set(val) {
        return val;
      },
      // ;
    },
  },
  created() {
    this.getRole();
  },
  mounted() {
    // this.handleClick();
  },
  methods: {
    ...mapMutations(["addBreadcrumb"]),
    async getRole() {
      const res = await this.$get("/user/userRole");
      // console.log(res);
      if (res.flag) {
        let arr = [];
        menu.map((item) => {
          if (res.data.indexOf(item.meta.title) !== -1) {
            arr.push(item);
          }
        });
        this.menu = arr;
        // this.menu = menu;
      }
    },
    handleClick(key, keyPath) {
      this.list = [];
      menu.map((item) => {
        this.mapList(item, keyPath);
      });
      //
    },
    mapList(val, arr) {
      if (arr) {
        if (arr.indexOf(val.path) !== -1) {
          this.list.push(val.meta.title);
          if (val.children) {
            val.children.map((item) => {
              this.mapList(item, arr);
            });
          }
          this.addBreadcrumb(this.list);
        }
      } else {
        if (!JSON.parse(sessionStorage.getItem("tag"))) {
          this.addBreadcrumb(["用户管理"]);
        } else {
          this.addBreadcrumb(JSON.parse(sessionStorage.getItem("tag")));
        }
      }
      // ;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-submenu__title {
  font-size: 18px;
  .iconfont {
    margin-right: 10px;
  }
}
/deep/.el-menu-item {
  font-size: 18px;
  .iconfont {
    margin-right: 10px;
  }
}
</style>
