<template>
  <div>
    <!-- 一级菜单 -->
    <el-menu-item v-if="!route.children" :index="route.path">
      <i :class="['iconfont', route.icon]"></i>
      <span slot="title">{{ route.meta.title }}</span>
    </el-menu-item>
    <!-- 多级菜单(递归组件) -->
    <el-submenu v-else :index="route.path">
      <template slot="title">
        <i :class="['iconfont', route.icon]"></i>
        <span slot="title">{{ route.meta.title }}</span>
      </template>
      <my-menu
        v-for="item in route.children"
        :key="item.index"
        :route="item"
      ></my-menu>
    </el-submenu>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "myMenu",
  props: {
    route: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {},
  mounted() {
    // console.log(this.route);
  },
};
</script>

<style scoped lang="less">
.iconfont {
  font-size: 23px;
  padding-right: 4px;
}
</style>
