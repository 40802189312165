<template>
  <div class="head">
    <h3 class="left">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item v-for="(item, index) in breadcrumb" :key="index">{{
          item
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </h3>

    <div class="right">
      <p>今日扫码开门次数:{{ inoutNum }}次</p>
      <p>总次数:{{ allNum }}次</p>
      <div class="username" @click="banck">退出</div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "myHead",
  data() {
    return {
      lsit: [],
      inoutNum: null,
      allNum: null,
    };
  },
  computed: {
    ...mapState(["breadcrumb"]),
    breadcrumb: {
      get(val) {
        return this.$store.state.breadcrumb;
      },
      set() {},
    },
  },
  mounted() {
    this.getNum();
    this.getNumAll();
  },
  methods: {
    // 获取开门扫次数
    async getNum() {
      const res = await this.$get("/code/inCount");
      this.inoutNum = res.data;
    },
    // 总次数
    async getNumAll() {
      const res = await this.$get("/code/allCount");
      this.allNum = res.data;
    },
    banck() {
      sessionStorage.removeItem("token");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
<style scoped lang="less">
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  .el-icon-caret-bottom {
    color: #fff;
    padding-left: 10px;
    cursor: pointer;
    font-size: 12px;
  }
  /deep/.el-breadcrumb__inner {
    color: #fff !important;
    font-size: 16px;
  }
}

.right {
  display: flex;
  align-items: center;
  p {
    margin-right: 30px;
  }
  .username {
    margin-right: 10px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
  }
  .useravatar {
    width: 30px;
    height: 30px;
    background-image: url("../../assets/env.png");
    background-size: cover;
  }
}
.avatar-wrapper {
  display: flex;
  align-items: center;
}
</style>
