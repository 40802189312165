import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './publics/element'
import './assets/iconfont/iconfont.css'
import {get, post, put, del, exl,dow } from './axios/http'
import Video from 'video.js'
import 'video.js/dist/video-js.css'


Vue.config.productionTip = false

Vue.prototype.$get = get
Vue.prototype.$post = post
Vue.prototype.$put = put
Vue.prototype.$del = del
Vue.prototype.$exl = exl
Vue.prototype.$video = Video
Vue.prototype.$dow = dow
console.log(process.env.VUE_APP_BASE_API);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
