const menu = [
    {
        path: '/stage',
        name: 'stage',
        icon: "icon-yonghuguanli",
        meta: {
            title: '驿站信息'
        },
    },
    {
        path: "/communityService",
        name: "communityService",
        icon: "icon-yonghuguanli",
        meta: {
            title: "社区服务",
        },
        children: [
            {
                path: "/lifeCircle",
                name: "lifeCircle",
                meta: {
                    title: "便民生活圈",
                },
            },
            {
                path: "/speaker",
                name: "speaker",
                meta: {
                    title: "社区喇叭",
                },
            },
            {
                path: "/activity",
                name: "activity",
                meta: {
                    title: "活动坊",
                },
            },
            {
                path: "/communityService/life",
                name: "communityService/life",
                icon: "icon-yonghuguanli",
                meta: {
                    title: "生活服务",
                },
                children: [
                    {
                        path: "/service",
                        name: "service",
                        meta: {
                            title: "服务管理",
                        },
                    },
                    {
                        path: "/type",
                        name: "type",
                        meta: {
                            title: "类型配置",
                        },
                    },
                ]
            }
        ],
    },
    {
        path: "/reservation",
        name: "reservation",
        icon: "icon-yonghuguanli",
        meta: {
            title: "预约管理",
        },
        children: [
            {
                path: "/delayed",
                name: "delayed",
                meta: {
                    title: "延时服务预约",
                },
            },
            {
                path: "/resource",
                name: "resource",
                meta: {
                    title: "资料内容配置",
                },
            },
        ]
    },
    {
        path: "/UserManagement",
        name: "UserManagement",
        icon: "icon-yonghuguanli",
        meta: {
            title: "用户管理",
        },
        children: [
            {
                path: "/User",
                name: "User",
                meta: {
                    title: "用户查看",
                },
            },
            {
                path: "/Company",
                name: "Company",
                meta: {
                    title: "公司管理",
                },
            },
        ],
    },
    // {
    //     path: '/codeManagement',
    //     name: 'codeManagement',
    //     icon: 'icon-erweima',
    //     meta: {
    //         title: '场所码管理'
    //     }
    // },
    {
        path: "/thread",
        name: "thread",
        icon: "icon-tijiaoshujuxiansuo",
        meta: {
            title: "线索管理",
        },
        children: [
            {
                path: "/checkThread",
                name: "checkThread",
                meta: {
                    title: "查看线索",
                },
            },
            {
                path: "/typeThread",
                name: "typeThread",
                meta: {
                    title: "线索类型",
                },
            },
        ],
    },
    {
        path: "/task",
        name: "task",
        icon: "icon-renwujianying",
        meta: {
            title: "任务管理",
        },
        children: [
            {
                path: "/lookTask",
                name: "lookTask",
                meta: {
                    title: "查看任务",
                },
            },
            {
                path: "/TypeTask",
                name: "TypeTask",
                meta: {
                    title: "任务类型",
                },
            },
            {
                path: "/task",
                name: "task",
                meta: {
                    title: "志愿者任务",
                },
            },
            {
                path: "/score",
                name: "score",
                meta: {
                    title: "志愿者积分明细",
                },
            },
        ],
    },
    {
        path: "/volu",
        name: "volu",
        icon: "icon-gongyi",
        meta: {
            title: "公益管理",
        },
        children: [
            {
                path: "/volunteer",
                name: "volunteer",
                meta: {
                    title: "查看公益",
                },
            },
        ],
    },
    {
        path: "/goodsManagement",
        icon: "icon-shangpinguanli",
        meta: {
            title: "商品管理",
        },
        children: [
            {
                path: "/goods",
                name: "goods",
                meta: {
                    title: "查看商品",
                },
            },
            {
                path: "/goodsRecord",
                name: "goodsRecord",
                meta: {
                    title: "兑换记录",
                },
            },
        ],
    },
    {
        path: "/riderMien",
        name: "riderMien",
        icon: "icon-qishou",
        meta: {
            title: "骑手风采",
        },
    },
    {
        path: "/System",
        name: "System",
        icon: "icon-xitongguanli",
        meta: {
            title: "系统管理",
        },
        children: [
            {
                path: "/Swiper",
                name: "Swiper",
                meta: {
                    title: "轮播图管理",
                },
            },
            {
                path: "/Communication",
                name: "Communication",
                meta: {
                    title: "小区楼宇通行证",
                },
            },
            {
                path: "/BackStageUser",
                name: "BackStageUser",
                meta: {
                    title: "后台用户管理",
                },
            },
            {
                path: "/About",
                name: "About",
                meta: {
                    title: "关于我们",
                },
            },
            {
                path: "/Logs",
                name: "Logs",
                meta: {
                    title: "操作日志",
                },
            },
            // {
            //     path: '/Authority',
            //     name: 'Authority',
            //     meta: {
            //         title: '权限管理'
            //     }
            // },
        ],
    },
];

export default menu;
