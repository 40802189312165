import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Layout from "../components/layout/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../views/Login/Login.vue"),
    meta: {
      title: "首页",
    },
  },
  {
    path: "/Layout",
    name: "Layout",
    component: Layout,
    redirect: "/stage",
    children: [
      {
        path: '/stage',
        name: 'stage',
        component: () => import('../views/stage/stage.vue'),
        meta: {
          title: '驿站信息'
        },
      },
      {
        path: '/lifeCircle',
        name: 'lifeCircle',
        component: () => import('../views/communityService/lifeCircle.vue'),
        meta: {
          title: '便民生活圈'
        },
      },
      {
        path: '/speaker',
        name: 'speaker',
        component: () => import('../views/communityService/speaker.vue'),
        meta: {
          title: '社区喇叭'
        },
      },
      {
        path: '/activity',
        name: 'activity',
        component: () => import('../views/communityService/activity.vue'),
        meta: {
          title: '活动坊'
        },
      },
      {
        path: '/service',
        name: 'service',
        component: () => import('../views/communityService/service.vue'),
        meta: {
          title: '服务管理'
        },
      },
      {
        path: '/type',
        name: 'type',
        component: () => import('../views/communityService/type.vue'),
        meta: {
          title: '类型管理'
        },
      },
      {
        path: '/delayed',
        name: 'delayed',
        component: () => import('../views/reservation/delayed.vue'),
        meta: {
          title: '延时服务预约'
        },
      },
      {
        path: '/resource',
        name: 'resource',
        component: () => import('../views/reservation/resource.vue'),
        meta: {
          title: '资料内容配置'
        },
      },
      {
        path: "/User",
        name: "User",
        component: () => import("../views/User/UserInfo/User.vue"),
        meta: {
          title: "用户管理",
        },
      },
      {
        path: "/Company",
        name: "Company",
        component: () => import("../views/User/Company/Company.vue"),
        meta: {
          title: "公司管理",
        },
      },
      {
        path: "/System",
        name: "System",
        meta: {
          title: "系统管理",
        },
      },
      {
        path: "/Role",
        name: "Role",
        component: () => import("../views/System/Role/Role.vue"),
        meta: {
          title: "角色管理",
        },
      },
      {
        path: "/BackStageUser",
        name: "BackStageUser",
        component: () =>
          import("../views/System/BackStageUser/BackStageUser.vue"),
        meta: {
          title: "后台用户管理",
        },
      },
      {
        path: "/Swiper",
        name: "Swiper",
        component: () => import("../views/System/Swiper/Swiper.vue"),
        meta: {
          title: "轮播图管理",
        },
      },
      {
        path: "/Authority",
        name: "Authority",
        component: () => import("../views/System/Authority/Authority.vue"),
        meta: {
          title: "权限管理",
        },
      },
      {
        path: "/details",
        name: "details",
        component: () => import("../views/details/details.vue"),
        meta: {
          title: "权限管理详情",
        },
      },
      {
        path: "/codeManagement",
        name: "codeManagement",
        component: () => import("../views/codeManagement/codeManagement.vue"),
        meta: {
          title: "场所码管理",
        },
      },
      {
        path: "/thread",
        name: "thread",
        meta: {
          title: "线索管理",
        },
      },
      {
        path: "/checkThread",
        name: "checkThread",
        component: () => import("../views/thread/checkThread/checkThread.vue"),
        meta: {
          title: "查看线索",
        },
      },
      {
        path: "/typeThread",
        name: "typeThread",
        component: () => import("../views/thread/typeThread/typeThread.vue"),
        meta: {
          title: "线索类型",
        },
      },
      {
        path: "/lookTask",
        name: "lookTask",
        component: () => import("../views/task/lookTask/lookTask.vue"),
        meta: {
          title: "查看任务",
        },
      },
      {
        path: "/task",
        name: "task",
        component: () => import("../views/task/task.vue"),
        meta: {
          title: "志愿者任务",
        },
      },
      {
        path: "/score",
        name: "score",
        component: () => import("../views/task/score.vue"),
        meta: {
          title: "志愿者积分明细",
        },
      },
      {
        path: "/volunteer",
        name: "volunteer",
        component: () => import("../views/volunteer/volunteer.vue"),
        meta: {
          title: "查看公益",
        },
      },
      {
        path: "/volunteerDetails",
        name: "volunteerDetails",
        component: () => import("../views/volunteer/volunteerDetails.vue"),
        meta: {
          title: "活动详情",
        },
      },
      {
        path: "/goods",
        name: "goods",
        component: () => import("../views/goodsManagement/goods/goods.vue"),
        meta: {
          title: "查看商品",
        },
      },
      {
        path: "/goodsRecord",
        name: "goodsRecord",
        component: () =>
          import("../views/goodsManagement/goodsRecord/goodsRecord.vue"),
        meta: {
          title: "兑换记录",
        },
      },
      {
        path: "/riderMien",
        name: "riderMien",
        component: () => import("../views/riderMien/riderMien"),
        meta: {
          title: "骑手风采",
        },
      },
      {
        path: "/Communication",
        name: "Communication",
        component: () =>
          import("../views/User/Communication/Communication.vue"),
        meta: {
          title: "通行证",
        },
      },
      {
        path: "/About",
        name: "About",
        component: () => import("../views/System/About/About.vue"),
        meta: {
          title: "关于我们",
        },
      },
      {
        path: "/Logs",
        name: "Logs",
        component: () => import("../views/System/Logs/Logs.vue"),
        meta: {
          title: "操作日志",
        },
      },
      {
        path: "/TypeTask",
        name: "TypeTask",
        component: () => import("../views/task/lookTask/TypeTask.vue"),
        meta: {
          title: "任务类型",
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  if (sessionStorage.getItem("token")) {
    // 如果已经登录的话
    next();
  } else {
    if (to.path === "/") {
      // 如果是登录页面的话，直接next()
      next();
    } else {
      // 否则 跳转到登录页面
      next({
        path: "/",
      });
    }
  }

  // if(to.name === 'Login') next();
  // // console.log(sessionStorage.getItem('token'));
  // if(sessionStorage.getItem('token')){
  //   next()
  // }else {
  //   if(to.name !== 'Login') {
  //     console.log(to);
  //     router.push('/Login')
  //     next()
  //   }

  // }
  // to and from are both route objects. must call `next`.
});

export default router;
