import Vue from 'vue';

import {
    Upload, Message, Checkbox, MessageBox, CheckboxGroup, Pagination, Image, Form, FormItem, Dialog, Button, Input, Select, Table, TableColumn, Option, BreadcrumbItem, Breadcrumb, Col, RadioGroup, RadioButton, Card, Tabs, TabPane, Row, Icon, Menu, MenuItem, MenuItemGroup, Submenu, Footer, Main, Header, Container, Aside, Dropdown, DropdownMenu,
    DropdownItem, DatePicker, TimeSelect, Radio
} from 'element-ui';
Vue.use(CheckboxGroup)
Vue.use(Upload)
Vue.use(DatePicker)
Vue.use(Checkbox)
Vue.use(Image)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Dialog)
Vue.use(Pagination)
Vue.use(Option)
Vue.use(Select)
Vue.use(Button)
Vue.use(Col)
Vue.use(Card)
Vue.use(Row)
Vue.use(Icon)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Submenu)
Vue.use(Footer)
Vue.use(Main)
Vue.use(Header)
Vue.use(Container)
Vue.use(Aside)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(RadioButton)
Vue.use(RadioGroup)
Vue.use(Dropdown)
Vue.use(BreadcrumbItem)
Vue.use(Breadcrumb)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Input)
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(TimeSelect);
Vue.use(Radio);

Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;