import axios from 'axios'
import qs from 'qs'

axios.defaults.baseURL = "/api"
// axios.defaults.baseURL = "http://192.168.2.74:8080"
// const url = "http://192.168.1.11:9015"
axios.defaults.timeout = 10000
axios.defaults.withCredentials = true
// console.log(process.env.NODE_ENV)
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// 请求拦截器
axios.interceptors.request.use(config => {

    let token = sessionStorage.getItem('token')
    // if (config.params) {
    //     if (config.params.isResponseType) {
    //         config.responseType = "blob"
    //     }
    // }
    token && (config.headers.jti = token)
    return config
}, error => {
    console.log(error)
    return Promise.reject(error)
})
// 响应拦截
axios.interceptors.response.use(async response => {
    if (response.status === 200) {
        return Promise.resolve(response.data);
    } else {
        return Promise.reject(response);
    }
}, error => {
    console.log(error)
    return Promise.reject(error);
})
export function put(url, params) {
    return new Promise((resolve, reject) => {
        axios.put(url, params)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    })
}



export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err)
            })
    })
}
export function del(url, params) {
    return new Promise((resolve, reject) => {
        axios.delete(url, params)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err)
            })
    })
}
export const exl = function (url, params) {
    return new Promise((resolve, reject) => {
        axios(url, {
            params: params,
            responseType: "blob",
        })
            .then((res) => {
                //   if (res) {
                //     ElMessage.success("导出成功");
                //   } else {
                //     ElMessage.error("导出失败！");
                //   }
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
export const dow = function (url, params) {
    return new Promise((resolve, reject) => {
        axios({
            url,
            method: 'POST',
            data: params,
            responseType: 'blob',
        })
            .then((res) => {
                //   if (res) {
                //     ElMessage.success("导出成功");
                //   } else {
                //     ElMessage.error("导出失败！");
                //   }
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

