<template>
  <div class="container">
    <el-container>
      <el-aside width="250px" class="aside">
        <div class="header">
          <img src="../../assets/logo.png" alt="" />
        </div>
        <MyAside />
      </el-aside>
      <el-container>
        <el-header><my-head /></el-header>
        <el-main><router-view /></el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import MyHead from "./myhead";
import MyAside from "./myaside";
export default {
  name: "layout",
  components: {
    MyHead,
    MyAside,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.header {
  width: 250px;
  height: 158px;
  background: #ececec;
  line-height: 158px;
  text-align: center;
  background: #304156;
  img {
    width: 250px;
    height: 158px;
  }
}
/deep/.el-aside {
  overflow: hidden !important;
}
/deep/.el-header {
  background: #304156;
}
.el-main {
  height: calc(100vh - 60px);
  background-color: #e9eef3;
  color: #333;
  // text-align: center;
  overflow: hidden;
}
.el-menu {
  height: 100%;
  border: none;
}
</style>
